import React, { PureComponent } from "react";
import Logo from "../../images/logo.png";
import CardDecline from "../../images/card-decline.png";
import { CloseIcon } from "../../components/icons";
import { Link } from "react-router-dom";
import { loadPayengine } from "payengine";
import PayabliComponent from "../payabli/component";
import "./styles.scss";
import moment from "moment-timezone";
import CryptoJS from "crypto-js";
import { LoadingOutlined } from '@ant-design/icons';
import visa2 from "../../images/visa_2.svg";
import { Spin } from 'antd';
import {numberFormat , generateUniqueNum} from "../../constants/common";

import { CheckCircleOutlined, DollarCircleOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{
  fontSize: 24, margin: "20px",
  "margin-bottom": "20px",
  "padding": "30px",
  "text-align": "center",
  "border-radius": "4px"
}} spin />;



export default class ReviewPayInvoiceComponent extends PureComponent {
  constructor(props) {
    const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
    super(props);
    this.state = {
      decryptedData: {},
      isBtnDisabled: false,
      isPaymentDone: false,
      invoiceData: {},
      initialValues: {
        number: "",
        expDate: "",
        nameOnCard: "",
        securityCode: ""
      },
      payabliCustomerMethod: {},
      isPaybli: false,
      isLoader: false,
      paybliDetails: {},
      uuid : "",
      previousState: this.props?.location?.state?.from,
      buttonClicked:false,
      paymentButtonLoader:false,
      isPaybaliCardDecline:false,
      handlingFee:null,
      updateCard:false,
      cardAdded:false,
      cardPayment:true,
      bankPayment:false,
      tz,
      customerData:{}
    };
  }
  createSecureFields(pe) {
    let css = {};
    pe.SecureFields.create().then((form) => {
      form.field("#cc-name", {
        type: "text",
        name: "card_holder",
        placeholder: "Card holder",
        validations: ["required"],
        css,
      });
      form.field("#cc-type", {
        type: "text",
        name: "cardType",
        placeholder: "Card Type",
        validations: ["required"],
        css,
      });

      form.field("#cc-number", {
        type: "card-number",
        name: "card_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Card number",
        showCardIcon: false,
        validations: ["required"],
        css,
      });
      form.field("#cc-cvc", {
        type: "card-security-code",
        name: "card_cvc",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "CVC",
        maxLength: 4,
        validations: ["required"],
        css,
      });
      form.field("#cc-expiration-date", {
        type: "card-expiration-date",
        name: "card_exp",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "MM / YY",
        validations: ["required"],
        css,
      });
      this.setState({ secureForm: form,isLoader:false });
    });
  }
  createSecureBankFields(pe) {
    let css = {};
    pe.SecureFields.create().then((form) => {
      form.field("#first-name", {
        type: "text",
        name: "first_name",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "First Name",
        validations: ["required"],
        css,
      });
      form.field("#last-name", {
        type: "text",
        name: "last_name",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Last Name",
        validations: ["required"],
        css,
      });
      form.field("#routing-number", {
        type: "number",
        name: "routing_number",
        placeholder: "Routing Number",
        validations: ["required"],
        css,
      });
      form.field("#account-number", {
        type: "number",
        name: "account_number",
        successColor: "#4F8A10",
        errorColor: "#D8000C",
        placeholder: "Account Number",
        validations: ["required"],
        css,
      });
      this.setState({ secureForm: form,isLoader:false });
    });
  }

  async addCard(e) {
    
    this.setState({ resultMessage: "", isBtnDisabled: true });
    e.preventDefault();
    try {
      // setIsBtnDisabled(true);
      let obj = {}
      if (this.state.cardPayment === true) {
        obj = await this.state.secureForm.createCard();
      }
      if (this.state.bankPayment === true) {
        obj = await this.state.secureForm.createBankAccount();
      }
      //const totalAmout = Number(this.state.decryptedData?.total) - Number(this.state.decryptedData.amountPaid)
      const totalAmout = (this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) ? 
      Number(this.state.invoiceData.total - this.state.invoiceData.amountPaid)
      :Number(this.state.decryptedData?.total) - Number(this.state.decryptedData.amountPaid)
      let payload = {
        token: obj.token,
        isPayFromEmail: true,
        amount: totalAmout ? totalAmout : 0,
        customerId: this.state.decryptedData?.company?.customerId,
        companyId: this.state.decryptedData?.company?.companyId,
        invoiceId: this.state.decryptedData?.invoiceId,
        uniqueId: this.state.uuid,
        stripeCardFees: this.state.handlingFee?this.state.handlingFee:0,
        paymentType:this.state.cardPayment === true ? "credit-card" :""
      };
      if (this.state.bankPayment === true) {
        payload.stripeCardFees = 0
      }
      const { value } = await this.props.payInvoice(payload);
      if (value.status) {
        Object.assign(this.state.invoiceData,{total:0,invoiceAmount:0,amountPaid:0});
        Object.assign(this.state.decryptedData, {total:0,amountPaid:0});
        this.setState({ isPaymentDone: true,handlingFee:0})
      }
      else{
        const UUID = generateUniqueNum();
        this.setState({uuid : UUID})
      }
    } catch (e) {
      this.setState(
        {
          resultMessage: e?.data?.message,
          isBtnDisabled: false,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }

  numberWithCommas = (x) => {
    var parts = x.toFixed(2).split(".");
    var num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
      (parts[1] ? "." + parts[1] : "");
    return num;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://embedded.payabli.com/1.4/component.js";
    script.async = true;
    script.onload = () => { console.log('script loaded##########') };
    document.body.appendChild(script);

    let bytes = CryptoJS.AES.decrypt(
      this.props.location.search.replaceAll("?", ""),
      "secret-key"
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    const UUID = generateUniqueNum();
    this.setState({
      decryptedData: decryptedData,
      uuid : UUID
    });
    this.fetchInvoiceData(decryptedData?.invoiceId,decryptedData)
    
  }

  makePayabliPayment = async () => {
    this.setState({ paymentButtonLoader: true })
    const totalAmout = (this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.customerPayabliData?.StoredMethods[0].Method === 'card') ? 
    Number(this.state.invoiceData.total - this.state.invoiceData.amountPaid)
    :Number(this.state.decryptedData?.total) - Number(this.state.decryptedData.amountPaid)
    const payload = {
      cardData: {
        storedMethodId:
          this.state.payabliInfo.referenceId,
        payabliInfo:
          this.state.payabliCustomerMethod?.StoredMethods?.find((card)=>{return card.IdPmethod === this.state.payabliInfo.referenceId}),  
      },
      paymentDetails: { totalAmount: totalAmout },
      customerData: {
        customerId: this.state.payabliCustomerMethod?.customerId,
      },
      companyId: this.state.decryptedData?.company?.companyId,
      entryPoint: this.state.paybliDetails?.entry_point,
      orderId: this.state.decryptedData?.invoiceId,
      isPayFromEmail: true,
      userId: this.state.decryptedData.userId,
      ticketNumber: this.state.invoiceData?.ticketNumber,
      stripeCardFees: this.state.handlingFee?this.state.handlingFee:0,
      paymentType:this.state.customerPayabliData?.StoredMethods[0].Method === 'card' ? "credit-card" :""
    };
    const response = await this.props.makeTransaction(payload);
    if(response.value.status === true) {
      if (response?.value.data.isSuccess === true) {
      this.setState({ isCharged: true,paymentButtonLoader: false }, () => {
        //this.fetchInvoiceData(this.state.decryptedData?.invoiceId)
        // this.updatePaymentInfo()
    
          this.setState({ isPaymentDone: true,handlingFee:null })
           this.fetchInvoiceData(this.state.invoiceData?.invoiceId) 
     
        
      })
      this.setState({ reRender: this.state.reRender })
    } else {
      this.setState({ isCharged: false,paymentButtonLoader: false,resultMessage:response.value.data.responseText,isPaybaliCardDecline:true})
    }
  } else {
      this.setState({ isCharged: false,paymentButtonLoader: false,resultMessage:response.value.message,isPaybaliCardDecline:true})
    }
  };

  callApi = async (details) => {
    const { value } = await this.props.checkPabliIntegration({ companyId: details.company.companyId })
    if (value.status) {
      this.setState({ isPaybli: true, paybliDetails: value.data })
      if (this.state.buttonClicked === true) {
        this._initPayabli()
      }
    }else{
      this.handleCardPayment()
    }
  }

  _initPayabli = () => {
    // let entryBytes = CryptoJS.AES.decrypt(this.state.paybliDetails.entry_point,'weigh123!!');
    // const decryptedEntryPoint = entryBytes.toString(CryptoJS.enc.Utf8)
    // let paybliTokenBytes = CryptoJS.AES.decrypt(this.state.paybliDetails.mixed_token,'weigh123!!');
    // const decryptedToken = paybliTokenBytes.toString(CryptoJS.enc.Utf8)
    var self = this;
    var payabliConfig0 = {
      type: "methodLightbox",
      rootContainer: "pay-component-1",
      buttonLabelInModal: 'Save Payment Method',
      defaultOpen: 'card',
      hideComponent: true,
      temporaryToken: false,
      token: this.state.paybliDetails?.public_token,
      entryPoint: this.state.paybliDetails?.entry_point,
      card: {
        enabled: true,
        amex: false,
        discover: true,
        visa: true,
        mastercard: true
      },
      ach: {
        enabled: true,
        checking: true,
        savings: false
      },
      customerData: {
        customerNumber: this.state.customerData?.phone,
        firstName: this.state.customerData?.customerName,
        lastName: this.state.customerData?.displayName,
        billingEmail: this.state.customerData?.billingEmailAddress,
        company: this.state.customerData?.companyName
      },
      functionCallBackSuccess: (response) => {
        if (response.isSuccess) {
          self.setState({ isLoader: true })
          self.assignCardData(response.responseData)
        }
      },
      functionCallBackError: function (errors) {
      }
    };

    var paycomponent0 = new PayabliComponent(payabliConfig0, this.props.closeCardModal)
    paycomponent0.showModal();
  }


  assignCardData = async (cardData) => {
    this.setState({ payabliInfo: cardData, isAddNewCard: false });
    const payload = {
      _id: this.state.decryptedData?.company?.customerId ? this.state.decryptedData?.company?.customerId : this.state.decryptedData?.customerId,
      companyId: this.state.decryptedData?.company?.companyId,
      payabliCustomer: cardData,
    };
    await this.props.updateCustomer(payload);
    this.setState({ cardAdded: true });
    this.getCustomerPayment(cardData?.customerId);
  }

  getCustomerPayment = async (value) => {
    const {decryptedData,invoiceData,updateCard} = this.state
    const payload = {
      customerId: value,
      companyId: this.state.decryptedData?.company?.companyId
    };
    const {
      value: { data },
    } = await this.props.getPayabliCustomerData(payload);
    const newStoredMethod = data &&  data.StoredMethods?.filter((items,i)=>data.StoredMethods.length - 1 === i )
    const newData = Object.assign(data,{StoredMethods:newStoredMethod})
    const method = newData.StoredMethods[0].Method
    if ((decryptedData && decryptedData?.company?.chargeStripeCardFees === true)) {
      if (method === 'card') {
        if ( updateCard === false ) {
          const totalfee=this.calcFee(invoiceData.total-invoiceData.amountPaid, 'USD')
          Object.assign(invoiceData,{invoiceAmount:totalfee.amount})
          this.setState({ customerPayabliData: newData, payabliCustomerMethod: newData,isLoader:false,isBtnDisabled:false,handlingFee:totalfee.fee });
          this.setState({ reRender: !this.state.reRender });
        }else {
          const payload = {
            invoiceId: invoiceData.invoiceId,
            companyId: this.state.decryptedData?.company?.companyId,
          }
          const { value: { data: { invoicedata } } } = await this.props.getInvoiceDataForEmail(payload);
          const totalfee=this.calcFee(invoicedata.total-invoiceData.amountPaid, 'USD')
          Object.assign(invoiceData,{invoiceAmount:totalfee.amount})
          this.setState({ customerPayabliData: newData, payabliCustomerMethod: newData,isLoader:false,isBtnDisabled:false,handlingFee:totalfee.fee });
          this.setState({ reRender: !this.state.reRender });
        }
      }
      else{
        const payload = {
          invoiceId: invoiceData.invoiceId,
          companyId: this.state.decryptedData?.company?.companyId,
        }
        const { value: { data: { invoicedata } } } = await this.props.getInvoiceDataForEmail(payload);
        this.setState({ customerPayabliData: newData, payabliCustomerMethod: newData,isLoader:false,isBtnDisabled:false,invoiceData:invoicedata,updateCard:false });
        this.setState({ reRender: !this.state.reRender });
      }
    } else {
    this.setState({ customerPayabliData: newData, payabliCustomerMethod: newData,isLoader:false,isBtnDisabled:false });
    this.setState({ reRender: !this.state.reRender });
    }
    
  };
  round2decimal = (number) => {
    return Number(numberFormat(Math.round(parseFloat(number) * 1000) / 1000));
  };

  calcFee(amount, currency) {
    const _fee = this.state.decryptedData?.company?.chargeStripeCardFeesAmount ? this.state.decryptedData?.company?.chargeStripeCardFeesAmount : "3"
    amount = this.round2decimal(amount);
    let fee =
      amount * (this.round2decimal(_fee) / 100) 
    fee = this.round2decimal(fee);
    const total = this.round2decimal(amount + fee);
    return {
      amount: this.round2decimal(amount),
      fee: this.round2decimal(fee),
      total: this.round2decimal(total),
    };
  }

  updatePaymentInfo = async () => {
    let payload = {
      invoiceId: this.state.invoiceData?.invoiceId,
      invoiceAmount: this.state.invoiceData.total - this.state.invoiceData.amountPaid,
      customerId: this.state.decryptedData?.company?.customerId ? this.state.decryptedData?.company?.customerId : this.state.decryptedData?.customerId,
      companyId: this.state.decryptedData?.company?.companyId,
      
    }
    const { value } = await this.props.updatePaymentInfo(payload);
    if (value.status) {
      this.setState({ isPaymentDone: true,handlingFee:null })
       this.fetchInvoiceData(this.state.invoiceData?.invoiceId) 
    }
  }

  fetchInvoiceData = async (invoiceId,decryptedData) => {
    const payload = {
      invoiceId: invoiceId,
      companyId: decryptedData?.company?.companyId,
    }
    const { value: { data: { invoicedata } } } = await this.props.getInvoiceDataForEmail(payload);
    this.setState({ invoiceData: invoicedata, customerData:invoicedata?.customer }, () => {
      if (!this.state.isPaymentDone) {
        this.callApi(this.state.decryptedData)
        this.forceUpdate()
      }
    })
   
  }

  handleAddCard = (e)=>{
    e.preventDefault()
    this.setState({buttonClicked:true})
    this.callApi(this.state.decryptedData)
    this.forceUpdate()
  }
  updateCard = (e)=>{
    e.preventDefault()
    this.setState({buttonClicked:true,updateCard:true})
    this.callApi(this.state.decryptedData)
    this.forceUpdate()
  }
  handleCardPayment = ()=>{
      this.setState({isLoader:true })
      const form = document.querySelector('.payengine__form');
      const iframes = form.querySelectorAll('iframe');
      if (iframes.length > 0) {
        if (iframes.length > 1) {
          for (let i = 0; i <= iframes.length - 1; i++) {
            if (iframes[i].style) {
              iframes[i].style.display = 'none';
            }
          }
        }
      }
    this.setState({secureForm:{}})
      loadPayengine({
        publicKey: process.env.REACT_APP_PAY_ENGINE_PUBLIC_KEY,
      }).then((pe) => {
        if (this.state.cardPayment === true) {
          if ((this.state.decryptedData &&this.state.decryptedData?.company?.chargeStripeCardFees === true && this.state.isPaybli === false)) {
            const totalfee=this.calcFee(this.state.invoiceData.total-this.state.invoiceData.amountPaid, 'USD')
                Object.assign(this.state.invoiceData,{invoiceAmount:totalfee.amount})
                this.setState({ handlingFee:totalfee.fee });
          }
          this.createSecureFields(pe)
        }
      });
  }
  handleBankPayment = ()=>{
    this.setState({isLoader:true, handlingFee: 0})
    const form = document.querySelector('.payengine__form');
    const iframes = form.querySelectorAll('iframe');
    if (iframes.length > 0) {
      if (iframes.length > 1) {
        for (let i = 0; i <= iframes.length - 1; i++) {
          if (iframes[i].style) {
            iframes[i].style.display = 'none';
          }
        }
      }
    }
    this.setState({secureForm:{}})
      loadPayengine({
        publicKey: process.env.REACT_APP_PAY_ENGINE_PUBLIC_KEY,
      }).then(async (pe) => {
        if (this.state.bankPayment === true) {
          const payload = {
            invoiceId: this.state.invoiceData.invoiceId,
            companyId: this.state.decryptedData?.company?.companyId,
          }
          const { value: { data: { invoicedata } } } = await this.props.getInvoiceDataForEmail(payload);
          this.setState({ invoiceData:invoicedata })
          this.createSecureBankFields(pe)
        }
      });
    
  }
  insertSlash(val) {
    return val.replace(/^(\d{2})(\d{2})/, "$1/$2");
  }
  
  render() {
    return (
      <>
        {Object.keys(this.state.invoiceData).length !== 0 &&
          <main className="dashboard-layout-content review__pay-invoice">
            <div className="head-section-header d-flex align-items-center">
              <div className="head-section-cta">
                <Link to={this.state.previousState ? this.state.previousState : "/"} className="btn btn-link btn-back">
                  <CloseIcon />
                </Link>
              </div>
            </div>
            <div className="review__pay-invoice-inner">
              <div className="review__pay-list">
                <div className="review__pay-items payment__card">
                  <div className="card">
                    <div className="card-header">
                      <div className="row align-items-center">
                        <div className="col-md-12 col-sm-12 col-xs-12 col-xl-12 d-flex flex-unset align-items-center justify-content-between">
                          <div>
                            <h5 className="card-title">Payment Amount</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!this.state.isPaymentDone && this.state.invoiceData?.paymentStatus !== "paid" && !this.state.isPaybaliCardDecline ?

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="amount-details">
                              <h5 className="title">Date</h5>
                              <p className="sub-title">
                                {moment(new Date())?.tz(this.state?.tz)?.format("MM/DD/YYYY")}
                              </p>
                            </div>
                            <div className="amount-details">
                              <h5 className="title">Payment Method</h5>
                            </div>
                          </div>
                        </div>
                        {!this.state.isPaybli ? (
                        <div className="row mb-4">
                          <div className="col-md-6">
                          <button
                            onClick={(e) => {this.setState({cardPayment:true,bankPayment:false},()=>{this.handleCardPayment()})}}
                            className={`${this.state.bankPayment === true ? 'card-payment' : 'credit-card-button'} btn btn-default btn-lg w-100 font-600`}
                            
                          >
                            Credit Card
                          </button>
                          </div>
                          <div className="col-md-6">
                          <button
                           onClick={(e) => {this.setState({cardPayment:false,bankPayment:true},()=>{this.handleBankPayment()})}}
                           className={`${this.state.cardPayment === true ? 'bank-payment' : 'bank-button'} btn btn-default btn-lg w-100 font-600`}
                          >
                            Bank
                          </button>
                          </div>
                        </div>
                        ):null}
                        <div className="row">
                          <div className="col-md-12">
                          <ul className="filter__list">
                          
                          {this.state.customerPayabliData?.StoredMethods?.length >
                          0 &&
                          this.state.customerPayabliData?.StoredMethods.map(
                            (item) => {
                              return item.Method === 'card' ?(
                                <li className="filter__list-item p-0">
                                  <span className="d-flex -align-items-center filter__check">
                                      <p className="card__number">
                                        <img src={visa2} alt="" />{" "}
                                        {item.MaskedAccount}{" "}
                                        <span>
                                          {this.insertSlash(item.ExpDate)}{" "}
                                        </span>
                                      </p>
                                  </span>
                                </li>
                              ):<li className="filter__list-item p-0">
                              <span className="d-flex -align-items-center filter__check">
                                <p className="card__number">
                                  Routing Number: {item?.ABA}
                                </p>
                              </span>
                              <span className="d-flex -align-items-center filter__check">
                                <p className="card__number">
                                  Account Number: {item?.MaskedAccount}
                                </p>
                              </span>
                            </li>
                              
                            }
                          )}
                              
                          </ul>
                          </div>
                        </div>
                        
                        {!this.state.isPaybli ?
                          this.state.cardPayment === true && this.state.bankPayment === false ?
                          (
                            <Spin spinning={this.state.isLoader} indicator={antIcon}>
                            <div className="row" >
                            <div className="col-lg-12" id="remove">
                              <form id="card-form" className="payengine__form">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div
                                      className="payengine-field"
                                      id="cc-type"
                                    ></div>
                                    <br />
                                    <div
                                      className="payengine-field"
                                      id="cc-name"
                                    ></div>
                                    <br />
                                    <div
                                      className="payengine-field"
                                      id="cc-number"
                                    ></div>
                                    <br />
                                    <div
                                      className="payengine-field"
                                      id="cc-expiration-date"
                                    ></div>
                                    <br />
                                    <div
                                      className="payengine-field"
                                      id="cc-cvc"
                                    ></div>
                                  </div>
                                </div>
                                <br />
                                <br />
                                
                              </form>
                            </div>
                              
                          </div>
                          </Spin>
                          ):
                          this.state.bankPayment === true && this.state.cardPayment === false  ?(
                          
                            <Spin spinning={this.state.isLoader} indicator={antIcon} >
                              <div className="row" >
                          <div className="col-lg-12" id="removed">
                            <form id="card-form" className="payengine__form">
                              <div className="row">
                                <div className="col-md-12">
                                  <div
                                    className="payengine-field"
                                    id="first-name"
                                  ></div>
                                  <br />
                                  <div
                                    className="payengine-field"
                                    id="last-name"
                                  ></div>
                                  <br />
                                  <div
                                    className="payengine-field"
                                    id="routing-number"
                                  ></div>
                                  <br />
                                  <div
                                    className="payengine-field"
                                    id="account-number"
                                  ></div>
                                  <br />
                                </div>
                              </div>
                              <br />
                              <br />
                              
                            </form>
                          </div>
                           
                         </div>
                         </Spin>
                         ):null
                          :
                          this.state.isLoader ?
                            <Spin className="loader__full" indicator={antIcon} />
                            :
                            <div className='react-modal-dialog react-modal-dialog-centered sustainability__information-modal'>
                              <div id="pay-component-1">
                              </div>
                            </div>

                        }
                       {!this.state.isPaybli ? <div className="row mt-2">
                          <div className="col-md-12">
                            <button
                              onClick={(e) => this.addCard(e)}
                              disabled={this.state.isBtnDisabled}
                              className="btn btn-dark btn-lg w-100 font-600"
                            >
                              Submit Payment
                            </button>
                          </div>
                          <div className="col-md-12">
                            {this.state.resultMessage !== "" && (
                              <pre id="result" className="text-danger mt-3">{this.state.resultMessage}</pre>
                            )}
                          </div>
                        </div>
                        :
                        <>
                        <div className="row">
                          <div className="col-md-12">
                            {this.state.customerPayabliData?.StoredMethods?.length > 0 ? 
                            <button
                            onClick={(e) => this.updateCard(e)}
                            className="btn btn-lg w-100 font-600 text-light"
                            style={{backgroundColor:'#00C577'}}
                          >
                            Update Card
                          </button>
                            :<button
                            onClick={(e) => this.handleAddCard(e)}
                            className="btn  btn-lg w-100 font-600 text-light"
                            style={{backgroundColor:'#00C577'}}
                          >
                            Add Card
                          </button>
                            }
                            
                          </div>
                        </div>
                        {this.state.cardAdded && (<div className="row mt-2">
                        <div className="col-md-12">
                          <button
                            onClick={() => {this.makePayabliPayment();}}
                            disabled={Boolean(this.state.customerPayabliData?.StoredMethods?.length === 0) || this.state.paymentButtonLoader}
                            className="btn btn-dark btn-lg w-100 font-600"
                          >
                            {this.state.paymentButtonLoader === true? "Please wait...":"Submit Payment"}
                          </button>
                        </div>
                        <div className="col-md-12  mt-2">
                          {this.state.resultMessage !== "" && (
                            <pre id="result" className="text-danger">{this.state.resultMessage}</pre>
                          )}
                        </div>
                      </div>)}
                      </>
                        }

                        <div className="payment__condition-info">
                          <p>
                            By selecting Pay, I accept the{" "}
                            <a href={"https://curbwaste.com/terms"} alt=''>Terms of Service</a> and have read and
                            acknowledge the Privacy Statement. I also allow{" "}
                            {
                              this.state.decryptedData?.company?.businessInfo
                                ?.businessName
                            }{" "}
                            to charge ${this.state.decryptedData?.total} amount to
                            my card on date.
                          </p>
                        </div>
                      </div>
                      :
                      <div>
                        {(!this.state.isPaymentDone) && this.state.invoiceData?.paymentStatus === "paid" &&
                          <div className="thanks-you-block">
                            <div className="thankyou-icon-block">
                              <DollarCircleOutlined />
                            </div>
                            <div className="text-block">
                              <h5>You have already paid this invoice and you do not have any balance due, Thank you</h5>
                            </div>
                          </div>
                        }
                        {this.state.isPaymentDone &&
                          <div className="thanks-you-block">
                            <div className="thankyou-icon-block">
                              <CheckCircleOutlined />
                            </div>
                            <div className="text-block">
                              <h4>Thank You!</h4>
                              <h5>Your submission has been sent</h5>
                            </div>
                          </div>
                        }
                        {this.state.isPaybaliCardDecline &&
                          <div className="thanks-you-block">
                            <div className="thankyou-icon-block">
                              <img className="card-decline-icon" src={CardDecline} alt=""/>
                            </div>
                            <div className="text-block">
                              <h2 className="card-decline-title">Card Decline</h2>
                              <button onClick={()=>{window.location.reload();}} className="btn btn-dark">Please try again!</button>
                            </div>
                          </div>
                        }
                      </div>

                    }
                  </div>
                </div>
                <div className="review__pay-items payment__amount-invoice">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <Link to={"/"}>
                            <img
                              className="logo"
                              src={
                                this.state.decryptedData?.company?.logo !== ""
                                  ? this.state.decryptedData?.company?.logo
                                  : Logo
                              }
                              alt="logo"
                            />
                          </Link>
                          <h4>
                            {
                              this.state.decryptedData?.company?.businessInfo
                                ?.businessName
                            }
                          </h4>
                          <ul className="payment__invoice-list">
                            <li className="items">
                              <span className="title">Invoice #:</span>
                              <span className="sub-title">
                                {this.state.decryptedData?.invoiceId}
                              </span>
                            </li>
                            <li className="items">
                              <span className="title">Due Date: </span>
                              <span className="sub-title">
                                {moment(this.state.decryptedData?.dueDate)?.tz(this.state?.tz)?.format(
                                  "MM/DD/yyyy"
                                )}
                              </span>
                            </li>
                            <li className="items">
                              <span className="title">Invoice Amount:</span>
                              <span className="sub-title">
                                ${ this.numberWithCommas(Number((this.state.invoiceData?.total).toFixed(2)))
                                // (this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.customerPayabliData?.StoredMethods[0].Method === 'card') && (!this.state.isPaymentDone) ? 
                                // this.numberWithCommas(Number(this.state.invoiceData.invoiceAmount)) : 
                                // (this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.isPaybli === false) && (this.state.cardPayment === true) ?
                                // this.numberWithCommas(Number(this.state.invoiceData.invoiceAmount)):
                                // this.numberWithCommas(Number(this.state.invoiceData.total - this.state.invoiceData.amountPaid))
                                }
                              </span>
                            </li>
                            <li className="items">
                              <span className="title">Invoice Due Amount:</span>
                              <span className="sub-title">
                                ${(this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.customerPayabliData?.StoredMethods[0].Method === 'card') && (!this.state.isPaymentDone) ? 
                                this.numberWithCommas(Number(this.state.invoiceData.invoiceAmount)) : 
                                (this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.isPaybli === false) && (this.state.cardPayment === true) ?
                                this.numberWithCommas(Number(this.state.invoiceData.invoiceAmount)):
                                this.numberWithCommas(Number(this.state.invoiceData.total - this.state.invoiceData.amountPaid))
                                }
                              </span>
                            </li>
                            {(this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.customerPayabliData?.StoredMethods[0].Method === 'card') ? <li className="items">
                              <span className="title">Handling fee:</span>
                              <span className="sub-title">
                              ${this.numberWithCommas(Number(this.state.handlingFee))}
                              </span>
                            </li> :(this.state.decryptedData && this.state.decryptedData?.company?.chargeStripeCardFees === true) && (this.state.isPaybli === false) && (this.state.cardPayment === true)?
                              <>
                              <li className="items">
                              <span className="title">Handling fee:</span>
                              <span className="sub-title">
                              ${this.numberWithCommas(Number(this.state.handlingFee))}
                              </span>
                              </li>
                              </>
                              :null}
                          </ul>
                          <ul className="total__list">
                            <li className="items">
                              <span>Total</span>
                              <span>${this.numberWithCommas(Number((Number((this.state.invoiceData.total - this.state.invoiceData.amountPaid) + this.state.handlingFee)).toFixed(2)))}</span>
                            </li>
                          </ul>
                          <button
                            onClick={() =>
                              window.open(
                                this.state.decryptedData.viewInvoiceUrl,
                                "_blank"
                              )
                            }
                            className="btn btn-dark view__invoice-btn"
                          >
                            View Invoice{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>}
      </>
    );
  }
}
