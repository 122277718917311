import moment from "moment-timezone";
import _ from 'lodash'

const numberFormat = number => {
  return Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false
  });
};
const numberFormatWidthTypeNumber = number => {
  return parseFloat(Number(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }));
};

const reOrderArray = (arr) => {
  const arrayWithPaymentInvoice = arr.filter(obj => obj.isRecivePaymentInvoice === true);
  const arrayWithOutPaymentInvoice = arr.filter(obj => obj.isRecivePaymentInvoice !== true);
  const reorderedArray = arrayWithPaymentInvoice.concat(arrayWithOutPaymentInvoice);
  return reorderedArray;
};

const numberFormatLbs = number => {
  return Number(number).toLocaleString(undefined, {
    // minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const numberWithCommas = (x) => {
  var parts = x.toFixed(2).split(".");
  var num =
    parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    (parts[1] ? "." + parts[1] : "");
  return num;
};

const generateUniqueNum = () =>{
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 10000000);
  return `${timestamp}-${randomNum}`;
}

const round2decimal = (number) => {
  number= Number(number)
  return Math.round(parseFloat(number) * 100) / 100;
};

const getStartDate = (duration, date) => {
  const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
  let startDate = moment()?.tz(tz)?.startOf('day');
  switch (duration) {
    case "today":
      startDate = moment()?.tz(tz)?.startOf("day");
      break;
    case "week":
      startDate = moment()?.tz(tz)
        ?.clone()
        ?.startOf("isoWeek");
      break;
    case "month":
      startDate = moment()?.tz(tz)?.startOf("month");
      break;
    case "this week":
      startDate = moment()?.tz(tz)
        ?.clone()
        ?.startOf("isoWeek");
      break;
    case "this month":
      startDate = moment()?.tz(tz)?.startOf("month");
      break;
    case "custom":
      startDate = moment(date, "MMM DD, YYYY")?.tz(tz);
      break;
    default:
      startDate = moment()?.tz(tz);
  }
  return startDate;
};

const getEndDate = (duration, date) => {
  const tz = localStorage.getItem("timezone") ||  moment?.tz?.guess()
  let endDate = moment()?.tz(tz)?.endOf("day");
  switch (duration) {
    case "today":
      endDate = moment()?.tz(tz)?.endOf("day");
      break;
    case "week":
      endDate = moment()?.tz(tz)
        ?.clone()
        ?.endOf("isoWeek");
      break;
    case "this week":
      endDate = moment()?.tz(tz)
        ?.clone()
        ?.endOf("isoWeek");
      break;
    case "month":
      endDate = moment()?.tz(tz)?.endOf("month");
      break;
    case "this month":
      endDate = moment()?.tz(tz)?.endOf("month");
      break;
    case "custom":
      endDate = moment(date, "MMM DD, YYYY")?.tz(tz);
      break;
    default:
      endDate = moment()?.tz(tz)?.endOf("day");
  }
  return endDate;
};

const format_address = company => {
  const addressParts = []

  if (company?.city?.length > 0)  ( company?.zip?.length > 0 || company?.state?.length > 0 ) ? addressParts.push(company?.city+",") : addressParts.push(company?.city) ;

  if (company?.state?.length > 0 ) addressParts.push(company?.state);

  if (company?.zip?.length > 0) addressParts.push(company?.zip);

  return addressParts?.length > 0 ? addressParts.join(" ") : "";
}

const truckTypes = [
  { name: "Box Truck", value: "Box Truck" },
  { name: "Car", value: "Car" },
  { name: "Dual Axle", value: "Dual Axle" },
  { name: "Dump Trailer", value: "Dump Trailer" },
  { name: "Dump Truck", value: "Dump Truck" },
  { name: "Garbage Truck", value: "Garbage Truck" },
  { name: "Pick Up", value: "Pick Up" },
  { name: "Quad Axle", value: "Quad Axle" },
  { name: "Roll Off", value: "Roll Off" },
  { name: "Semi", value: "Semi" },
  { name: "Single Axle", value: "Single Axle" },
  { name: "Tri Axle", value: "Tri Axle" },
  { name: "Van", value: "Van" },
  { name: "Walking Floor", value: "Walking Floor" }
];

const outboundTruckTypes = [
  { name: "Box Truck", value: "Box Truck" },
  { name: "Car", value: "Car" },
  { name: "Dual Axle", value: "Dual Axle" },
  { name: "Dump Trailer", value: "Dump Trailer" },
  { name: "Dump Truck", value: "Dump Truck" },
  { name: "Flat Bed", value: "Flat Bed" },
  { name: "Garbage Truck", value: "Garbage Truck" },
  { name: "Pick Up", value: "Pick Up" },
  { name: "Quad Axle", value: "Quad Axle" },
  { name: "Roll Off", value: "Roll Off" },
  { name: "Semi", value: "Semi" },
  { name: "Single Axle", value: "Single Axle" },
  { name: "Tri Axle", value: "Tri Axle" },
  { name: "Van", value: "Van" },
  { name: "Walking Floor", value: "Walking Floor" }
];
const materialOptions = [
  { value: 'appliances', label: 'Appliances' },
  { value: 'concrete', label: 'Concrete' },
  { value: 'brick', label: 'Brick' },
  { value: 'metal', label: 'Metal' },
  { value: 'wood', label: 'Wood' },
  { value: 'cardboard', label: 'Cardboard' },
  { value: 'asphalt', label: 'Asphalt' },
  { value: 'dirt/sand', label: 'Dirt/Sand' },
  { value: 'furniture', label: 'Furniture' },
  { value: 'glass', label: 'Glass' },
  { value: 'mixed c&d', label: 'Mixed C&D' },
  { value: 'mixed putrescible', label: 'Mixed Putrescible' },
  { value: 'plastic', label: 'Plastic' },
  { value: 'roofing', label: 'Roofing' },
  { value: 'screens', label: 'Screens' },
  { value: 'sheetrock', label: 'Sheetrock' },
  { value: 'stone', label: 'Stone' },
  { value: 'fill material', label: 'Fill Material' },
  { value: 'residual', label: 'Residual' },
  { value: 'carpet', label: 'Carpet' },
  { value: 'paper', label: 'Paper' },
  { value: 'pvc pipe', label: 'PVC Pipe' },
  { value: 'other', label: 'Other' },
];
const dumpRatesArray = [
  { value: "pricePerTonLight", label: "Ton Light" },
  { value: "pricePerTonHeavy", label: "Ton Heavy" },
  { value: "pricePerTonMixedCD", label: "Ton MixedCD" },
  { value: "pricePerTonWood", label: "Ton Wood" },
  { value: "pricePerTonMetal", label: "Ton Metal" },
  { value: "pricePerTonRoofing", label: "Ton Roofing" },
  { value: "pricePerTonMasonry", label: "Ton Masonry" },
  { value: "pricePerTonBrick", label: "Ton Brick" },
  { value: "pricePerTonDirt", label: "Ton Dirt" },
  { value: "pricePerTonConcrete", label: "Ton Concrete" },
  { value: "pricePerTonCD", label: "Ton CD" },
  { value: "pricePerTonLowRecovery", label: "Ton LowRecovery" },
  { value: "pricePerTonAsbestos", label: "Ton Asbestos" },
  { value: "pricePerTonOpenContainer", label: "Ton Open Container" },
  { value: "pricePerTonClosedContainer", label: "Ton Closed Container" },
  { value: "pricePerTonTrailer", label: "Ton Trailer" },
  { value: "pricePerYardLight", label: "Yard Light" },
  { value: "pricePerYardHeavy", label: "Yard Heavy" },
  { value: "pricePerYardMixedCD", label: "Yard MixedCD" },
  { value: "pricePerYardWood", label: "Yard Wood" },
  { value: "pricePerYardMetal", label: "Yard Metal" },
  { value: "pricePerYardRoofing", label: "Yard Roofing" },
  { value: "pricePerYardMasonry", label: "Yard Masonry" },
  { value: "pricePerYardBrick", label: "Yard Brick" },
  { value: "pricePerYardDirt", label: "Yard Dirt" },
  { value: "pricePerYardConcrete", label: "Yard Concrete" },
  { value: "pricePerYardCD", label: "Yard CD" },
  { value: "pricePerYardLowRecovery", label: "Yard LowRecovery" },
  { value: "pricePerYardOpenContainer", label: "Yard Open Container" },
  { value: "pricePerYardClosedContainer", label: "Yard Closed Container" },
  { value: "pricePerYardTrailer", label: "Yard Trailer" },
  { value: "pricePerYardAsbestos", label: "Yard Asbestos" },
];
const permissionOptions = [
  {
    label: "Complete Order",
    value: "completeOrder",
  },
  {
    label: "Create Manual Charge",
    value: "createManualCharge",
  },
  {
    label: "Delete Order",
    value: "deleteOrder",
  },
  {
    label: "Edit Business Settings",
    value: "editBusinessSettings",
  },
  {
    label: "Edit User Permissions",
    value: "editUserPermissions",
  },
  {
    label: "Export Orders & Reports",
    value: "exportOrdersAndReports",
  },
  {
    label: "Manually Enter Scale Weight",
    value: "manuallyEnterScaleWeight",
  },
  {
    label: "Sync to Quickbooks",
    value: "syncToQuickbooks",
  },
  {
    label: "Void Invoices",
    value: "voidInvoices",
  },
  {
    label: "Void Orders",
    value: "voidOrders",
  },
  {
    label: "Void Payments",
    value: "voidPayments",
  },
];

function sort_by_descending(array, key_name) {
  return array.sort((a, b) => {
    const propA = a[key_name]
    const propB = b[key_name]
    if (typeof propA === 'string' && typeof propB === 'string') {
      return propB.localeCompare(propA)
    } else if (propA > propB) {
      return -1
    } else if (propA < propB) {
      return 1
    } else {
      return 0
    }
  });
}

const paymentMethod = (item)=>{
  if (item?.paymentHistory) {
    if(item?.paymentHistory[0]?.type === "wire"){
      return `Wire`
    }
    if(item?.paymentHistory[0]?.type  === "credit-memo"){
      return `Credit Memo`
    }
    if(item?.paymentHistory[0]?.type  !== "credit-card" && item?.paymentHistory[0]?.type  !== "card" && item?.paymentHistory[0]?.type  !== "creditCard"){
      return item?.paymentHistory[0]?.type.replace("-"," ")
    }
    return `CC ${item?.paymentMethod?.PaymentMethodTypeStore?.value ? item?.paymentMethod?.PaymentMethodTypeStore?.value.slice(-4) : (item?.paymentHistory ? (item?.paymentHistory[0]?.paymentData?.MaskedAccount ? item?.paymentHistory[0]?.paymentData?.MaskedAccount?.slice(-4) : (item?.paymentHistory[0]?.paymentData?.SaleResponse?.maskedCardNumber?.slice(-4) ?? "-")) : "")}`;
  }
  return `CC `
}

const paymentMethodPosted = (item)=>{
  if(item?.customerReceivePayments?.paymentMethod?.paymentType  === "wire"){
    return `Wire`
  }
  if(item?.customerReceivePayments?.paymentMethod?.paymentType   === "credit-memo"){
    return `Credit Memo`
  }
  
  if (item?.customerReceivePayments?.paymentMethod?.paymentType === "check") {
    const checkNumber = item?.customerReceivePayments?.paymentMethod?.PaymentMethodTypeStore?.value;
    if (checkNumber) {
      return `Check ${checkNumber.slice(0, 5)}`;
    }
    return `Check`;
  }

  if (item?.customerReceivePayments?.paymentMethod?.paymentType === "ACH") {
    const accNumber = item?.customerReceivePayments?.paymentMethod?.PaymentMethodTypeStore?.value;
    if (accNumber) {
      return `ACH ${accNumber.substr(accNumber.length - 4)}`;
    }
    return `ACH`;
  }

  if(item?.customerReceivePayments?.paymentMethod?.paymentType  !== "credit-card" && item?.customerReceivePayments?.paymentMethod?.paymentType  !== "card" && item?.customerReceivePayments?.paymentMethod?.paymentType  !== "creditCard"){
      return item?.customerReceivePayments?.paymentMethod?.paymentType?.replace("-"," ")
  }
  if (item?.customerReceivePayments?.paymentMethod?.paymentType  === "credit-card" || item?.customerReceivePayments?.paymentMethod?.paymentType  === "card" || item?.customerReceivePayments?.paymentMethod?.paymentType  === "creditCard") {
    return `CC ${item?.customerReceivePayments?.paymentMethod?.PaymentMethodTypeStore?.value ? item?.customerReceivePayments?.paymentMethod?.PaymentMethodTypeStore?.value?.slice(-4) : `${item?.paymentHistory ? item?.paymentHistory[0]?.paymentData?.MaskedAccount ? item?.paymentHistory[0]?.paymentData?.MaskedAccount?.slice(-4) : item?.paymentHistory[0]?.paymentData?.SaleResponse?.maskedCardNumber.slice(-4) ?? "-":""}`}`
  }
  return `CC `
}

const isRoundOff = (amount) => (isCeilAmount = false) => {
  if(isCeilAmount){
    return Math.ceil(amount)
  }
  return amount
}

const roundOffDifference = (amount) => (isCeilAmount = false) => {
  if (isCeilAmount) {
    const ceilAmount = Math.ceil(amount);
    const difference = (ceilAmount - amount).toFixed(2);
    return parseFloat(difference);
  }
  return 0;
};

function customStartCase(item, type) {
  // Remove the type part from the item string
  const modifiedItem = item?.replace(type, '');
  
  // Use a regex to capture all alphanumeric sequences and special characters separately
  const parts = modifiedItem?.match(/[a-zA-Z0-9]+|[^a-zA-Z0-9]+/g);

  // Apply _.startCase to the alphanumeric parts only
  const transformedParts = parts?.map(part => {
    return /[a-zA-Z0-9]+/.test(part) ? _.startCase(part) : part;
  });

  // Join the transformed parts back together
  return transformedParts?.join('');
}

export { truckTypes, numberFormat, numberFormatLbs, getStartDate, getEndDate, format_address, numberWithCommas ,numberFormatWidthTypeNumber, round2decimal, sort_by_descending, paymentMethod, paymentMethodPosted, outboundTruckTypes,reOrderArray,materialOptions, isRoundOff,permissionOptions, roundOffDifference, dumpRatesArray, customStartCase , generateUniqueNum};
